<template>
    <div class="two-factor-authentication">
        <div v-if="!isLoading" @keypress.enter.prevent="verify">
            <div class="tip-title">
                <span v-if="isFirstLogin"
                    >{{ consts.firstLoginTitle }}
                    <font-awesome-icon
                        v-tooltip.top="consts.titleTooltip"
                        class="two-factor-authentication__info-icon"
                        :icon="['fal', 'info-circle']"
                    />
                </span>
                <span v-else>{{ consts.title }}</span>
            </div>
            <div class="two-factor-authentication__box-container">
                <div v-if="isFirstLogin" class="two-factor-authentication__box">
                    <two-factor-setup></two-factor-setup>
                </div>
                <div class="two-factor-authentication__box" :class="{ 'two-factor-authentication__box--no-shadow': !isFirstLogin }">
                    <two-factor-pin-code></two-factor-pin-code>
                </div>
                <button id="verifyButton" class="tip-custom-button" :class="buttonClass" :disabled="!canVerify || isVerifying" @click="verify">
                    <span v-if="!isVerifying">{{ consts.verifyButton }}</span>
                    <span v-else>
                        <font-awesome-icon :icon="['far', 'spinner']" pulse></font-awesome-icon>
                        <span class="two-factor-authentication__loading-text">{{ consts.verifyButtonLoading }}</span>
                    </span>
                </button>
            </div>
        </div>
        <two-factor-spinner></two-factor-spinner>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import twoFactorAuthenticationConsts from '@/consts/twoFactorAuthenticationConsts';
import loginStateService from '@/lib/loginStateService';

export default {
    name: 'TwoFactorAuthentication',
    components: {
        TwoFactorSetup: () => import('@/components/TwoFactorAuthentication/TwoFactorSetup'),
        TwoFactorPinCode: () => import('@/components/TwoFactorAuthentication/TwoFactorPinCode'),
        TwoFactorSpinner: () => import('@/components/TwoFactorAuthentication/TwoFactorSpinner')
    },
    data() {
        return {
            consts: twoFactorAuthenticationConsts
        };
    },
    computed: {
        ...mapState('twoFactor', ['canVerify', 'isVerifying', 'isLoading', 'validationResult']),
        ...mapGetters('twoFactor', ['isFirstLogin', 'isPinCodeValid', 'didReceiveErrorInSetup']),
        buttonClass() {
            if (this.isVerifying) {
                return 'tip-custom-button--loading';
            }
            return '';
        }
    },
    watch: {
        validationResult(newValue) {
            if (this.isPinCodeValid && newValue && newValue.appRedirectUrl) {
                window.location.href = newValue.appRedirectUrl;
            }
        }
    },
    async created() {
        await this.loadSetupModel();
        if (this.didReceiveErrorInSetup) {
            loginStateService.goToLogin();
        }
    },
    methods: {
        ...mapActions('twoFactor', ['loadSetupModel', 'validatePinCode']),
        verify() {
            if (this.canVerify && !this.isVerifying) {
                this.validatePinCode();
            }
        }
    }
};
</script>
<style lang="scss">
.two-factor-authentication {
    &__box-container {
        margin: auto;
        width: 40rem;
    }
    &__box {
        border: 1px solid $wild-sand;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
        margin: 0 auto 2rem auto;
        padding: 1.5rem 0;
        text-align: center;
    }
    &__box--no-shadow {
        border: none;
        box-shadow: none;
        padding: 0;
    }
    &__info-icon {
        height: 1.2rem;
        vertical-align: middle;
        &:focus {
            outline: none;
        }
    }
    &__for-tests {
        background: $gallery;
    }
    &__loading-text {
        margin-left: 1rem;
    }
}
</style>
